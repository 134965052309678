
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";

import { appId } from "@/../capacitor.config.json";

import { Route } from "vue-router/types/router";
import { User } from "@/app/modules/_global/types";

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    QrRegistrationButton: () => import("@/app/modules/auth/components/QrRegistrationButton.vue")
  }
})
export default class QrRegistrationPage extends Vue {
  @Prop(String) readonly uuid?: string;

  @Watch("$route")
  onRouteChanged(value: Route) {
    if (value.params.uuid) this.qrValidate();
  }

  userData: null | User = null;

  formData = {
    email: "",
    password: "",
    password_confirmation: "",
    qr_code: "",
    bundle: appId
  };

  acceptTerms = false;
  disclaimerDialog = false;
  confirmationMode = false;

  valid = true;
  validationErrors = null;
  errors: Record<string, string> = {};

  rules = {
    required: Rules.required,
    email: Rules.email
  };

  get appData() {
    return this.$store.getters["app/data"];
  }

  get firstName() {
    return this.userData?.person.first_name;
  }

  get lastName() {
    return this.userData?.person.last_name;
  }

  get fullName() {
    return this.userData ? `${this.firstName} ${this.lastName}` : null;
  }

  emailConfirmation() {
    this.validationErrors = null;
    this.confirmationMode = true;
  }

  qrValidate() {
    const formData = {
      qr_code: this.uuid as string
    };

    this.$store
      .dispatch("auth/qrValidate", formData)
      .then(({ data }) => {
        this.userData = data.data;
        this.formData.email = this.userData?.email ?? "";
      })
      .catch(error => {
        this.validationErrors = error.response.data.errors;
      });
  }

  qrRegister() {
    this.formData.qr_code = this.uuid as string;

    this.$store
      .dispatch("auth/qrRegister", this.formData)
      .then(() => this.welcome())
      .catch(error => {
        this.validationErrors = error.response.data.errors;
        this.confirmationMode = false;
      });
  }

  welcome() {
    this.$router.push({ name: "welcome" });
  }
}
